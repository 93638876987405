"use strict";

import loadScript from '@elements/load-script';

export function init() {
    
    if($('.js-payone').length){
        loadScript("https://secure.pay1.de/client-api/js/v1/payone_hosted.js").then(() => {

            let payoneLanguages = {
                'en': Payone.ClientApi.Language.en,
                'de': Payone.ClientApi.Language.de,
            }

            let payoneUsedLang = payoneLanguages[_config.lang];


            if (payoneUsedLang === undefined){
                payoneUsedLang = payoneLanguages['en']
            }

            var autoCcDetection = new PayoneAutoCcDetection({
                supportedCardTypes: _config.supportedCardTypes,
                hostedIframesConfig: {
                    config: {
                        fields: {
                            cardpan: {
                                selector: "payone-cc-pan",
                                type: "input"
                            },
                            cardcvc2: {
                                selector: "payone-cc-cvc",
                                type: "password",
                                size: "4",
                                maxlength: "4",
                                length: {"V": 3, "M": 3}
                            },
                            cardexpiremonth: {
                                selector: "payone-cc-expire-month",
                                type: "text",
                                size: "2",
                                maxlength: "2",
                            },
                            cardexpireyear: {
                                selector: "payone-cc-expire-year",
                                type: "text"
                            }
                        },
                        defaultStyle: {
                            input: "height: 100%; width: 100%; border: none; font-size: 20px; letter-spacing: 1px;",
                            iframe: {},
                        },

                        language: payoneUsedLang
                    },
                    request: _config.request
                }
            });

            document.querySelector('#submit-payment').addEventListener('click', function (event) {
                event.preventDefault();
                // TODO show loading
                autoCcDetection.performCreditCardCheck();
            });


            $('.js-payone__loading').attr('hidden','hidden');

        }).catch(console.error);
    }
}
