"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $sliders = $scope.find('.js-video-slider');

        import('slick-carousel').then(function() {
            $sliders.each(function() {

                let $slider = $(this);
                let $sliderWrapper = $slider.closest('.video-slider__wrapper');

                $slider.slick({
                    dots: true, /*should always be true; hide with css (accessibility)*/
                    arrows:true,
                    slidesToShow: 1,
                    infinite:false,
                    speed: 400,
                    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
                    prevArrow: $sliderWrapper.find('.js-video-slider__arrow-prev'),
                    nextArrow: $sliderWrapper.find('.js-video-slider__arrow-next'),
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                variableWidth: true,
                            }
                        }
                    ]
                });

            });
        })
    })
}