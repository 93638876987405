"use strict";

import {add} from '@elements/scroll-animations';
import onInitModulesInsScope from "./onInitModulesInScope";
import {setServicePermission,getServicePermission} from "./cookiebot-utils";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $cookieConsentButtons = $('.js-cookie-consent-button');

        $cookieConsentButtons.each(function(){
            let $cookieConsentButton = $(this);
            let service = $cookieConsentButton.data('cookie-consent-service');
            let type = $cookieConsentButton.data('cookie-consent-type');

            if(type === 'iframe'){
                let wrapper = $cookieConsentButton.closest('.js-cookie-consent__wrapper');
                let iframe = wrapper.find('.js-cookie-consent__iframe');
                let src = iframe.data('iframe-src');

                if(getServicePermission('marketing')){
                    iframe.prop('src', src);
                    iframe[0].style.zIndex = '2';
                    if($cookieConsentButton.closest('.js-cookie-consent-button__wrapper').length !== 0){
                        let buttonWrapper = $(this).closest('.js-cookie-consent-button__wrapper');
                        buttonWrapper[0].style.opacity = '0'
                        buttonWrapper[0].style.height = '0'
                    }
                }else{
                    $cookieConsentButton.on('click', function(e){
                        setServicePermission('marketing');
                        iframe.prop('src', src);
                        iframe[0].style.zIndex = '2';
                        if($cookieConsentButton.closest('.js-cookie-consent-button__wrapper').length !== 0){
                            let buttonWrapper = $(this).closest('.js-cookie-consent-button__wrapper');
                            buttonWrapper[0].style.opacity = '0'
                            buttonWrapper[0].style.height = '0'
                        }
                    });
                }
            }else{
                $cookieConsentButton.on('click', function(e){
                    setServicePermission('marketing');
                    location.reload();
                });
            }
        });
    })
}