"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init () {
    onInitModulesInsScope(function initInScope($scope) {
        let $forms = $scope.find('.js-step-form');

        $forms.each(function () {
            let $form = $(this);
            let $steps = $form.find('.js-step-form__step');
            let $legendItems = $form.find('.js-step-form__legend-item');
            let $stepNumber = $form.find('.js-step-form__current-step-number');

            $steps.each(function (index) {
                $(this).find(':input').attr('data-parsley-group', 'block-' + index);
            });
            navigateTo(0); // Start at the beginning

            $form.find('.js-step-form__next').on('click', function (evt) {
                evt.preventDefault();

                $form.parsley().whenValidate({
                    group: 'block-' + curIndex()
                }).done(function () {
                    navigateTo(curIndex() + 1);
                });
            });

            $form.find('.js-step-form__prev').click(function (evt) {
                evt.preventDefault();
                navigateTo(curIndex() - 1);
            });

            function curIndex() {
                return $steps.index($steps.filter('.is-current'));
            }

            function navigateTo(index) {
                $steps.removeClass('is-current').eq(index).addClass('is-current');
                $legendItems.removeClass('is-current').eq(index).addClass('is-current');
                $stepNumber.html(index + 1);
                let $prevBtn = $form.find('.js-step-form__prev');
                let $nextBtn = $form.find('.js-step-form__next');
                let $submitBtn = $form.find('.js-step-form__submit');

                if (index !== 0) {
                    $prevBtn.attr('hidden', false)
                } else {
                    $prevBtn.attr('hidden', true)
                }

                if (index >= $steps.length - 1) {
                    $nextBtn.attr('hidden', true);
                    $submitBtn.attr('hidden', false);
                    showResults($form.find('.js-step-form__result'), $form);
                } else {
                    $nextBtn.attr('hidden', false);
                    $submitBtn.attr('hidden', true);
                }
            }
        });

        function showResults($result, $form) {
            let $formElements = $form.find('input:not([type=hidden]), textarea, select');
            let inputStates = [];

            $formElements.each(function(_, el) {
                let newInputState;

                if($(el).is('select')) {
                    let option = $(el).find('option:selected');
                    newInputState = {
                        label: $(el).data('label') ? $(el).data('label') : $(el).attr('name'),
                        value: option.data('label') ? option.data('label') : $(el).val()
                    };
                } else {
                    if($(el).attr('name') !== 'g-recaptcha-response') {
                        newInputState = {
                            label: $(el).data('label') ? $(el).data('label') : $(el).attr('name'),
                            value: $(el).val()
                        };
                    }
                }

                inputStates.push(newInputState);
            });

            $result.empty();
            inputStates.map( element => {
                let itemHtml = renderResultItem(element);
                $result.append(itemHtml);
            });
        }

        function renderResultItem(element) {
            return `<li><strong class="mr-2">${element.label}:</strong>${element.value}</li>`;
        }
    })
}
