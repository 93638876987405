"use strict";
import loadScript from "@elements/load-script";
import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import onInitModulesInsScope from "./onInitModulesInScope";

let currentId = 1;
export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $recaptcha = $scope.find('.js-recaptcha');

        window.grecaptchaOnload = function () {
            // console.log('grecaptchaOnload');
            $recaptcha.each(function () {
                // console.log('recaptcha init');
                let $this = $(this);

                let widget = grecaptcha.render($this[0], {
                    'sitekey': _config.recaptchaSiteKey,
                    'callback': reCaptchaVerify
                });

                let $form = $(this).closest("form");

                addValidation($form, widget);

                function reCaptchaVerify(token) {
                    // console.log('verified', token);
                    $form.submit();
                }

                $this.closest('.js-ajax-form').on('failed.ajax-form', function () {
                    grecaptcha.reset(widget);
                });
            });
        };


        loadRecaptchaAPI().done(function () {
            if (typeof window.grecaptcha.render === 'function') {
                grecaptchaOnload();
            }
        });

    });
}

export function loadRecaptchaAPI() {
    return loadScript('https://www.google.com/recaptcha/api.js?onload=grecaptchaOnload&hl=' + _config.lang);
}

function addValidation($form, recaptcha) {
    $form.find('[type=submit]').on('click', function (evt) {
        evt.preventDefault();
        if (isParsleyForm($form) && !isValid($form)) {
            // grecaptcha.reset(recaptcha);
            return;
        }

        grecaptcha.execute(recaptcha);

    });
}
