import onInitModulesInScope from "../scripts/onInitModulesInScope";

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import * as goToLink from '@elements/go-to-link';
goToLink.init();

import * as toc from '@elements/toc';
toc.init();
onInitModulesInScope(toc.initInScope);

import * as lazyImg from '@elements/lazy-img';
onInitModulesInScope(lazyImg.initInScope);

import * as contentVisibility from '../scripts/content-visibility';
contentVisibility.init();

import * as teaserSliderSm from '../scripts/teaser-slider-sm';
teaserSliderSm.init();

import * as teaserSliderLg from '../scripts/teaser-slider-lg';
teaserSliderLg.init();

import * as hashCash from '../scripts/hashCash';
hashCash.init();

import * as cookieConsentOverlay from '../scripts/cookie-consent-overlay';
cookieConsentOverlay.init();

import * as search from '../scripts/search';
search.init();

$('.js-show-cookie-bar-modal').on('click', function(evt) {
    evt.preventDefault();
    Cookiebot.show();
});