"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init () {
    onInitModulesInsScope(function initInScope($scope) {
        // shows and hides the element in 'data-show-if-checked' on change
        let $inputs = $scope.find('.js-show-if-checked');

        $inputs.on('change', changeHandler);
    })
}

function changeHandler(evt) {
    let $this = $(this);

    // radio needs show-if-checked-wrapper
    if($this.is(':radio')){
        let $wrapper = $this.closest('.js-show-if-checked-wrapper');
        processRadioInput($wrapper, $this);
    } else {
        processInput($this);
    }
}

function processInput($input) {
    let $toggleEl = $($input.data('show-if-checked'));

    if ($input.is(':checked')) {
        $toggleEl.attr('hidden', null);
    } else {
        $toggleEl.attr('hidden', 'hidden');
    }
}
function processRadioInput($wrapper, $input) {
    if ($input.is(':checked')) {
        let $toggleEl = $wrapper.find($input.data('show-if-checked'));
        $toggleEl.attr('hidden', null);
        let $otherRadios = $wrapper.find('input[name="' + $input.attr('name') + '"]').not($input);

        $otherRadios.each(function () {
            let $otherToggleEl = $wrapper.find($(this).data('show-if-checked'));
            $otherToggleEl.attr('hidden', 'hidden');
        });
    }
}