"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init () {
    onInitModulesInsScope(function initInScope($scope) {
        const defaultSelectors = {
            base: '.js-content-visibility',
            styleClass: 'content-visibility--auto'
        };

        let base = $scope.find(defaultSelectors.base);

        if (base) {
            $(window).on('load', function () {
                base.removeClass(defaultSelectors.styleClass);
            });
        }
    });
}
