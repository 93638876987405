"use strict";


import onInitModulesInsScope from "./onInitModulesInScope";

function scrollTo ($target, offset = 0) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 500;


    let newOffset = $target.offset().top - offset;

    $('html, body').stop().animate({
        scrollTop: newOffset
    }, duration, 'swing');
}

function focus ($target) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}

export function init () {
    onInitModulesInsScope(function initInScope($scope) {
        $scope.find('.js-scroll-to').on('click', function (evt) {
            evt.preventDefault();
            console.log('clikc');
            let offset = $(this).data('scroll-offset');
            let targetSelector = $(this).data('scroll-target') || $(this).attr('href');
            let $target = $(targetSelector);

            switch ($(this).data('toggle')) {
                case 'tab':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopPropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    /*update bootstrap nav classes because bootstrap doesn't handle them*/
                    let navTabTrigger = $('.nav [data-toggle="tab"]').filter('[href="' + targetSelector + '"], [data-target="' + targetSelector + '"]');
                    navTabTrigger.each(function () {
                        let $navItems = $(this).closest('.nav').find('li');

                        $navItems.removeClass('active')
                            .find('a').attr('aria-expanded', 'false');

                        $(this).attr('aria-expanded', 'true')
                            .parent().addClass('active');

                    });
                    break;
                case 'collapse':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopImmediatePropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    break;
                default:
                    scrollTo($target, offset, function () {
                        focus($target);
                    });
            }
        });
    })
}
