
import * as nav from './nav';
nav.init();

import * as affixNav from './affixNavbar';
affixNav.init();

import * as cart from './cart';
cart.init();

import * as numberSpinner from './numberSpinner';
numberSpinner.init();

import * as numberSpinnerGroup from './numberSpinnerGroup';
numberSpinnerGroup.init();

import * as fadeIn from './fadeIn';
fadeIn.init();

import * as isInViewport from './isInViewport';
isInViewport.init();
