"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $wrapper = $scope.find('.js-special-teasers__wrapper');
        let $items = $wrapper.find('.js-special-teasers__item');

        $items.on('mouseover', function() {
            let $newWrapper = $(this).closest('.js-special-teasers__wrapper');
            let $newBgItems = $newWrapper.find('.js-special-teasers__bg-item');
            let $newItems = $newWrapper.find('.js-special-teasers__item');

            $newItems.removeClass('active initial-state');
            $(this).addClass('active');
            let $bgItem = $newWrapper.find($(this).data('target'));
            $newBgItems.removeClass('active');
            $bgItem.addClass('active');
        })
    })
}