"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        if (!matchMedia('(max-width: 767px)').matches) {
            return;
        }

        let $container = $scope.find('.js-center-active-tab');
        let $activeTab = $container.find('.active').closest('li');
        let $navTab = $container.find('li');
        $container.on('touchmove', function () {
            $(this).addClass('active');
        });
        center($navTab, $activeTab);

        $navTab.on('click', function () {
            center($navTab, $(this))
        });
    })
}

function center(navTab, tab) {
    if(tab && tab.length) {
        let childPos = tab.offset().left,
            $parent = tab.parent(),
            parentScroll = $parent.scrollLeft(),
            offset = childPos + parentScroll - $parent.innerWidth()/2 + tab.width()/2;

        navTab.closest('ul').animate({ scrollLeft: offset }, 250);
    }
}
