"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;


export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $navbar = $('.js-affix-navbar');
        let $navbarContainer = $navbar.closest('.js-affix-navbar__placeholder');
        let defaultTopPosition = $navbarContainer.offset().top;
        let navbarHeight;

        $(window).one('scroll', function () {
            $navbarContainer.css('overflow', 'hidden');
            navbarHeight = $navbarContainer[0].getBoundingClientRect().height || 45;
            $navbarContainer.css('overflow', '');
            /*get exact height (jquery returns rounded value)*/
            $navbarContainer.height(navbarHeight);
        });

        $navbarAffix = $scope.find('.js-affix-navbar');

        if ($navbarAffix) {
            navbarToggleAffix($navbarAffix);
        }
    });
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $('.js-affix-navbar__placeholder').offset().top;
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = offset < scrollTopPosition;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
    }
}

function setAffix() {
    $navbarAffix.addClass('is-affix');
    $navbarAffix.addClass('is-nav-white');
}

function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
    $navbarAffix.removeClass('is-nav-white');
}
