import $ from 'jquery';
import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification, clearAll} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch';
import 'whatwg-fetch'; // IE10 Polyfill
import fetch from '@elements/fetch';
import formDataEntries from 'form-data-entries';
import onInitModulesInsScope from "./onInitModulesInScope"; // IE10 Polyfill
import {translate} from '@elements/translations';

const configName = '_cartConfig';

let pendingRequest,
    $count;

export function init(){
    $count = $('.js-cart__count');
    

    // cart list count
    if (($count && $count.length)) {
        requireConfigValues(['infoUrl'], configName);

        let request = fetch(getConfigValue('infoUrl', configName), {
            method: 'get',
        });

        
        showNotification(request);

        request.then(response => response.json())
            .then(result => {
                if (result.success) {

                    if (typeof result.count !== "undefined") {
                        setCount($count, result.count);
                    }
                }
            }).catch(e => {
        });
    }
    onInitModulesInsScope(function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {
        let $cartForm = $scope.find('.js-cart__form');
        let $cartRemove = $scope.find('.js-cart__remove');
        let $cartLoading = $('.js-cart__loading');
        let $cartResult = $('.js-cart__result');
        let $cartNotifications = $('.js-cart__notifications');

        if ($cartForm && $cartForm.length) {

            let formTrigger = $cartForm.data('trigger') || 'submit';
            let emptyCart = $cartForm.data('empty-cart');

            requireConfigValues(['cartUrl'], configName);

            $cartForm.each(function () {
                let $this = $(this);
                $(this).on(formTrigger, function (evt) {
                    evt.preventDefault();
                    let $thisForm = $(this);


                    $cartLoading.attr('hidden', null);

                    if(emptyCart){
                        $cartResult.attr('hidden', 'hidden');
                    }

                    clearAll({
                        $container: $cartNotifications
                    });


                    let formDataEntries = getFormDataEntries([$thisForm[0]]);
                    let params = new URLSearchParams(formDataEntries);

                    let pendingRequest = fetch( getConfigValue('cartUrl', configName) , {
                        body: params
                    });

                    call(onAdd);

                    pendingRequest.then((result) => {
                        return result.clone().json()
                    }).then((result) => {
                            if (result.success) {
                                let content = result.content || result.html;
                                if (content) {
                                    $cartResult.empty().append(result.content);
                                    $cartLoading.attr('hidden', 'hidden');
                                    $cartResult.attr('hidden', null);
                                    initModulesInScope($cartResult);
                                }


                                if (typeof result.count !== "undefined") {
                                    setCount($count, result.count);
                                }

                                call(onAddSucceed);
                            } else {
                                call(onAddFailed);
                            }
                        }).catch(e => {
                            console.warn(e);
                            $cartLoading.attr('hidden', 'hidden');
                            $cartResult.attr('hidden', 'hidden');
                            call(onAddFailed);
                        });

                    showNotification(pendingRequest, {
                        $container: $cartNotifications
                    });

                });

                $this.find('.js-cart__amount').on('change', function () {
                    let count = 0;
                    for (let i = 0; i < $this.find('.js-cart__amount').length; i++) {
                        count = count + parseInt($this.find('.js-cart__amount')[i].value)
                    }

                    let $info = $this.find('.js-cart__amount-text');
                    let plural = translate('shop.tickets');
                    let singular = translate('shop.ticket');


                    if (count > 1) {
                        $info.text(plural);
                    } else {
                        $info.text(singular);
                    }
                });
            });


            // remove product from cart
            $cartRemove.on('click', function (evt) {
                evt.preventDefault();
                $(this).closest('form').find('.js-cart__amount').val('0').trigger('change');
            });
        }
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}


// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
