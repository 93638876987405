"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";
import loadScript from "@elements/load-script";
import {onEnterViewPort} from "@elements/viewport-utils";
import { getServicePermission } from "./cookiebot-utils";

let directionsRenderer;
let directionsService;
let map;
let mapMarker;
let overlays = new Array;

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $routePlanner = $scope.find('.js-route-planner');
        if ($routePlanner && $routePlanner.length) {
            $routePlanner.each(function (index, element) {
                let $container = $(element);
                let $routePlannerCanvas = $container.find('.js-route-planner__canvas');
                let canvasElement = $routePlannerCanvas[0];
                let directionPanel = $container.find('.js-route-planner__direction-panel')[0];

                if(getServicePermission('marketing')){
                    console.log('marketing true');
                    onEnterViewPort($(element), function () {
                        console.log('on enter viewport');
                        loadGoogleMapsAPI().then(function () {
                            console.log('load maps api');
                            initMap(canvasElement, directionPanel);

                            let $destinations = $container.find('.js-route-planner__destinations');
                            let $destinationInputs = $destinations.find('.js-route-planner__destination');
                            let $routePlannerForm = $container.find('.js-route-planner__form');
                            let routePlannerAddressElement = $container.find('.js-route-planner__address-input')[0];

                            // destination inputs which change end-address of direction panel
                            $destinationInputs.on('change', function () {
                                let latlng = $(this).val().split(",");
                                moveMarker(latlng[0], latlng[1]);
                            });

                            $routePlannerForm.on('submit', function (evt) {
                                evt.preventDefault();
                                evt.stopImmediatePropagation();
                                calcRoute(routePlannerAddressElement, $destinations);
                            });

                        })
                    });
                }
            });
        }
    })
}


function initMap(element, directionPanel) {
    directionsRenderer = new google.maps.DirectionsRenderer();
    directionsService = new google.maps.DirectionsService();

    let mapCenter = new google.maps.LatLng(47.491094, 11.095853);

    let mapOptions = {
        zoom: 10,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    map = new google.maps.Map(element, mapOptions);

    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(directionPanel);

    mapMarker = new google.maps.Marker({
        position: mapCenter,
        map: map
    });

    overlays.push(mapMarker);
}


function moveMarker(lat, lng) {

    while (overlays[0]) {
        overlays.pop().setMap(null);
    }

    mapMarker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: map
    });

    overlays.push(mapMarker);

    map.panTo(new google.maps.LatLng(lat, lng));
}

function calcRoute(routePlannerAddressElement, $destinations) {
    let $selectedDestination = $destinations.find('.js-route-planner__destination:checked');
    let $destinationWrapper = $selectedDestination.closest('.js-route-planner__destination-wrapper');
    let endAddress = $destinationWrapper.find('.js-route-planner__destination-text').text();

    let start = $(".js-route-planner__address-input").val();
    let end = endAddress;

    let request = {
        origin: start,
        destination: end,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
    };

    directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {

            while (overlays[0]) {
                overlays.pop().setMap(null);
            }

            directionsRenderer.setDirections(response);
            directionsRenderer.setOptions({suppressMarkers: true});
            let leg = response.routes[0].legs[0];

            let startmarker = new google.maps.Marker({
                position: leg.start_location,
                map: map,
                title: "Start"
            });

            let endmarker = new google.maps.Marker({
                position: leg.end_location,
                map: map,
                title: end
            });

            overlays.push(startmarker);
            overlays.push(endmarker);
        }
    });

}

export function loadGoogleMapsAPI() {
    if (!_config.googleMapAPIKey) {
        console.log('google map key not set.');
        return $.Deferred().reject(new Error('google map key is not set. Please set _config.googleMapAPIKey'));
    }

    let apiUrl = "https://maps.googleapis.com/maps/api/js?key=" + _config.googleMapAPIKey + "&language=" + _config.lang;

    // add google maps libraries (https://developers.google.com/maps/documentation/javascript/libraries)
    // possible libs: drawing, geometry, places, visualization
    if (_config.googleMapsLibraries) {
        // must be an array containing the needed libs (e.g. ["geometry","places"])
        apiUrl = apiUrl + "&libraries=" + _config.googleMapsLibraries.join(',');
    }

    return loadScript(apiUrl);
}
