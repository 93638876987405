"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {

        $scope.find('.js-number-spinner__down').on('click', function (evt) {
            let $spinner = $(this).closest('.js-number-spinner');
            let $input = $spinner.find('.js-number-spinner__input');
            let availableValues = $input.data('available-values');
            let $currentValue = $spinner.closest('.js-number-spinner__wrapper').find('.js-number-spinner__current-value');

            let newValue;

            if (typeof availableValues !== 'undefined') {
                let newIndex = availableValues.indexOf($input.val()) - 1;
                newValue = availableValues[newIndex];
            } else {
                newValue = +$input.val() - 1;
            }

            if ($input.attr('min')) {
                newValue = Math.max(+$input.attr('min'), newValue);
            }

            $input.attr('value', newValue);
            $input.val(newValue);
            $currentValue.text(newValue);
            $input.trigger('change', newValue);
            evt.preventDefault();
        });

        $scope.find('.js-number-spinner__up').on('click', function (evt) {
            let $spinner = $(this).closest('.js-number-spinner');
            let $input = $spinner.find('.js-number-spinner__input');
            let availableValues = $input.data('available-values');
            let $currentValue = $spinner.closest('.js-number-spinner__wrapper').find('.js-number-spinner__current-value');

            let newValue;

            if (typeof availableValues !== 'undefined') {
                let newIndex = availableValues.indexOf($input.val()) + 1;
                newValue = availableValues[newIndex];
            } else {
                newValue = +$input.val() + 1;
            }

            if ($input.attr('max')) {
                newValue = Math.min(+$input.attr('max'), newValue);
            }

            $input.attr('value', newValue);
            $input.val(newValue);
            $currentValue.text(newValue);
            $input.trigger('change', newValue);
            evt.preventDefault();
        });

        $scope.on('change', '.js-number-spinner__input', function (evt) {
            let $spinner = $(this).closest('.js-number-spinner');
            let $input = $spinner.find('.js-number-spinner__input');
            let availableValues = $input.data('available-values');
            let $currentValue = $spinner.closest('.js-number-spinner__wrapper').find('.js-number-spinner__current-value');
            let newValue = +$input.val();

            if ($input.attr('max')) {
                newValue = Math.min(+$input.attr('max'), newValue);
            }

            if ($input.attr('min')) {
                newValue = Math.max(+$input.attr('min'), newValue);
            }

            if (typeof availableValues !== 'undefined') {
                if (!availableValues.includes($input.val())
                    && +$input.val() > +$input.attr('min')
                    && +$input.val() < +$input.attr('max')) {
                    newValue = +$input.attr('min');
                }
            }

            $input.attr('value', newValue);
            $input.val(newValue);
            $currentValue.text(newValue);


            enableDisableButtons($spinner);

        });
    })
}



function enableDisableButtons($spinner) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t en-/disable buttons for element', $spinner, 'Element is not a number spinner ".js-number-spinner"');
        return;
    }

    let $input = $spinner.find('.js-number-spinner__input');
    let $btUp = $spinner.find('.js-number-spinner__up');
    let $btDown = $spinner.find('.js-number-spinner__down');
    let $btnDisable = $spinner.closest('.ticket').find($($spinner.data('button')));
    let inputVal = +$input.val();

    if ($input.attr('max')) {
        if(+$input.attr('max') == inputVal) {
            $btUp.attr('disabled', 'disabled');
        } else {
            $btUp.attr('disabled', null);
        }
    }

    if ($input.attr('min')) {
        if(+$input.attr('min') == inputVal) {
            $btDown.attr('disabled', 'disabled');
            $btnDisable.attr('disabled', 'disabled');

        } else {
            $btDown.attr('disabled', null);
            $btnDisable.attr('disabled', null);

        }
    }
}

export function setNumberSpinnerCount($spinner, count) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    $spinner.find('.js-number-spinner__input').attr('value',count);
    enableDisableButtons($spinner);
}

export function setNumberSpinnerMaxValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    $spinner.find('.js-number-spinner__input').attr('max', maxValue);
    enableDisableButtons($spinner);
}

export function getNumberSpinnerMaxValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    return $spinner.find('.js-number-spinner__input').attr('max');
}
export function getNumberSpinnerValue($spinner, maxValue) {
    if (!$spinner.is('.js-number-spinner')) {
        console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
        return;
    }

    return $spinner.find('.js-number-spinner__input').val();
}
