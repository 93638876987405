const MODULES_INJECTION_NAME = '__initInScopeInjection';
window._config = window._config || [];
window._config[MODULES_INJECTION_NAME] = true;

let subscriptions = [];
export default function onInitModulesInsScope(callback) {
    subscriptions.push(callback);

    return () => {
        subscriptions.splice(subscriptions.indexOf(callback), 1);
    }
}
window.app = window['app'] || {};
window.app.modules = window.app.modules || {};
window.app.modules[MODULES_INJECTION_NAME] = ($scope) => {
    subscriptions.forEach(x => x($scope))
};
