import $ from 'jquery';
import './scripts/common';
import './scripts/common-main-site';
import onInitModulesInScope from "./scripts/onInitModulesInScope";

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};


if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

window.app = window['app'] || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Content Stuff
 *
 * ********************************/

import * as videoJs from './scripts/videoJs';
onInitModulesInScope(videoJs.initInScope);

let selectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

import * as lightbox from '@elements/lightbox';
onInitModulesInScope(lightbox.createInitInScope(selectors, {
    iframeMaxWidth: '75%'
}));
$('.js-lightbox__item').on('click', function(){
    setTimeout(function(){
        let wrapper = $('.js-cookie-consent__wrapper');
        if(wrapper.length > 0){
            initModulesInScope( wrapper );
        }
    }, 500);
});

import * as showIfChecked from './scripts/showIfChecked';
showIfChecked.init();

import * as actionChanger from '@elements/action-changer';
onInitModulesInScope(actionChanger.initInScope);

import * as svgConverter from './scripts/svgConverter';
svgConverter.init();


import * as touchNav from '@elements/touch-nav';
import * as accessibilityNav from '@elements/accessibility-nav';
if(matchMedia('(min-width: 768px)').matches){
    onInitModulesInScope(accessibilityNav.initInScope);
    onInitModulesInScope(touchNav.initInScope);
}

import * as ajaxForm from '@elements/ajax-form';
onInitModulesInScope(ajaxForm.createInitInScope({
    addUrlParams: true
}));

import * as parallax from './scripts/parallax';
parallax.init();

import * as recaptcha from './scripts/recaptcha';
recaptcha.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();

import * as threesixtySlider from './scripts/threesixty-slider';
threesixtySlider.init();

import * as anchorNav from './scripts/anchor-nav';
anchorNav.init();

import * as specialTeasers from './scripts/specialTeasers';
specialTeasers.init();

import * as routePlanner from './scripts/routePlanner';
routePlanner.init();

import * as stepForm from './scripts/stepForm';
stepForm.init();

import * as fileInput from '@elements/file-input';
onInitModulesInScope(fileInput.initInScope);

import * as centerActiveTab from './scripts/centerActiveTab';
centerActiveTab.init();

import * as videoSlider from './scripts/videoSlider';
videoSlider.init();

import * as threeSixty from './scripts/three-sixty';
threeSixty.init();

import * as payone from './scripts/payone';
payone.init();

import * as datepicker from './scripts/datepicker';
onInitModulesInScope(datepicker.initInScope);

import * as datepickerRange from '@elements/datepicker-range';
onInitModulesInScope(datepickerRange.initInScope);



import * as parsley from '@elements/parsley-bootstrap-validation';
onInitModulesInScope(parsley.initInScope);

import * as floatingLabels from '@elements/floating-labels';
onInitModulesInScope(floatingLabels.initInScope);

import * as scrollTo from './scripts/scrollTo';
scrollTo.init();



(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);