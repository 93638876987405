"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let searchButton = $scope.find('.js-search__button');
        let searchInput = $scope.find('.js-search__input');

        searchButton.on('click', function (evt) {
            //sorry Timeout is needed because of modal
            setTimeout(() => {
                searchInput[0].focus();
            }, 500);
        });
    });
}