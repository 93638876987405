"use strict";

import {add} from '@elements/scroll-animations';
import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        if(matchMedia('(max-width: 767px)').matches) {
            return;
        }


        add(
            $scope.find('.js-parallax'),
            calculateAnimationProgress,
            setAnimationProgress
        );
    })
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight;
    const end = 0;

    return 1 - Math.max(
        Math.min(
            (element.getBoundingClientRect().top - end) / (start - end),
            1
        ),
        0
    );
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    let parallaxValue = $(element).data('parallax-value') * 1 || -30;

    let progressTranslate = progress * parallaxValue;
    element.style.transform = "translateY(" + progressTranslate + "px)";
}