"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    let $sliders = $('.js-threesixty-slider');

    import('slick-carousel').then(function() {
        $sliders.each(function() {
            let $slider = $(this);
            let $sliderWrapper = $slider.closest('.js-threesixty-slider__wrapper');

            $slider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                arrows:true,
                slidesToShow: 1,
                infinite:false,
                speed: 500,
                cssEase: 'ease',
                swipe: false,
                draggable: false,
                nextArrow: '<button type="button" class="threesixty-slider__arrow btn btn-slider-arrow slick-next" aria-label="Next"></button>',
                prevArrow: '<button type="button" class="threesixty-slider__arrow btn btn-slider-arrow slick-prev" aria-label="Previous"></button>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            variableWidth: true,
                        }
                    }
                ]
            });

        });
    })
}