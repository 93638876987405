import {getNumberSpinnerValue, setNumberSpinnerMaxValue} from "./numberSpinner";
import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        $scope.find('.js-number-spinner-group').on('change input', function (group, i) {
            let $group = $(this);
            let totalMax = +$group.data('number-spinner-group-max');
            let $items = $group.find('.js-number-spinner-group__item');
            let $currentValue = $group.find('.js-number-spinner-group__current-value');

            let currentTotal = $items.toArray().reduce((total, input) => {
                return total + (+getNumberSpinnerValue($(input)));
            }, 0);

            $currentValue.text(currentTotal);

            let remaining = totalMax - currentTotal;
            let togglesBtn = $group.data('number-spinner-group-toggle-btn') || false;

            if(togglesBtn) {
                let $toggleBtn = $group.find('.js-number-spinner-group__toggle-btn');
                if($toggleBtn.length > 0 || $toggleBtn !== 'undefined') {
                    let totalMin = +$group.data('number-spinner-group-toggle-btn-min');
                    if( currentTotal >= totalMin) {
                        $toggleBtn.attr('disabled', false);
                    } else {
                        $toggleBtn.attr('disabled', true);
                    }
                }
            }

            $items.each((index, element) => {
                let $element = $(element);
                setNumberSpinnerMaxValue($element, (+getNumberSpinnerValue($element)) + remaining);
                $currentValue.text(currentTotal);
            })
        })
    })
}