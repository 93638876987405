"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $sliders = $scope.find('.js-teaser-slider-sm');

        import('slick-carousel').then(function() {
            $sliders.each(function() {

                let $slider = $(this);
                let $sliderWrapper = $slider.closest('.js-teaser-slider-sm-wrapper');
                let centermode = ($slider.attr('data-centermode') === 'true');

                $slider.slick({
                    dots: true, /*should always be true; hide with css (accessibility)*/
                    arrows:true,
                    slidesToShow: 1,
                    infinite:false,
                    variableWidth: true,
                    centerMode: centermode,
                    speed: 500,
                    cssEase: 'ease',
                    prevArrow: $sliderWrapper.find('.js-teaser-slider-sm__arrow-prev'),
                    nextArrow: $sliderWrapper.find('.js-teaser-slider-sm__arrow-next')
                });

            });
        })
    })
}