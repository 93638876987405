"use strict";

import {add} from '@elements/scroll-animations';
import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        add(
            $scope.find('.js-fade-in'),
            calculateAnimationProgress,
            setAnimationProgress
        );
    })
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 10 * 9;
    const end = window.innerHeight / 1.5;

    return {
        opacity: 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        )
    }
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;
}