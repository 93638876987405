import 'bootstrap/js/dist/scrollspy';
import * as anchorNav from '@elements/anchor-nav';
import onInitModulesInsScope from "./onInitModulesInScope";

export function initAnchorNavi(){
    onInitModulesInsScope(anchorNav.createInitInScope({
            list: '.js-anchor-nav',
            listItem: '.js-anchor-nav__item'
        },  {
            renderItem: function (text, anchor) {
                return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link js-anchor-nav__link" data-smoothscroll-offset="200" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
            },
            smoothScroll: true,
            scrollSpy: true,
            scrollSpyOffset: 250,
            scrollSpyTarget: '.js-anchor-nav'
        })
    );
}

export function init() {
    if($('.js-anchor-nav').hasClass('js-anchor-nav--only-mobile')){
        if(matchMedia('(max-width: 767px)').matches) {
            initAnchorNavi();
        }
    }else{
        initAnchorNavi();
    }

    onInitModulesInsScope(function initInScope($scope) {
        if(matchMedia('(max-width: 767px)').matches) {
            let $dropdownButton = $scope.find('.js-anchor-nav__dropdown-button');
            let $dropdownText = $dropdownButton.find('.js-anchor-nav__dropdown-text');
            let $dropdown = $scope.find('.js-anchor-nav__dropdown');
            let $navItems = $dropdown.find('.js-anchor-nav__link');

            $(window).scroll(function(){
                clearTimeout($.data(this, 'scrollTimer'));
                $.data(this, 'scrollTimer', setTimeout(function() {

                    if($navItems.length > 0){
                        $navItems.each(function(i){

                            if($(this).hasClass('active')){
                                let text = $(this).text();
                                if($dropdownText.text() !== text){
                                    $dropdownText.text(text);
                                }
                            }
                        });
                    }
                }, 50));
            });

            $dropdownButton.on('click', function(e){
                if(!$dropdown.hasClass('is-active')){
                    $dropdown.addClass('is-active');
                }else{
                    $dropdown.removeClass('is-active');
                }
            });

            $(document).on('click', function(e){
                if(!$(e.target).is($dropdownText) && !$(e.target).is($dropdown) && !$(e.target).is($dropdownButton)){
                    if($dropdown.hasClass('is-active')){
                        $dropdown.removeClass('is-active');
                    }
                }
            })
        }

    });
}